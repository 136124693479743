<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0" v-if="!disabled">Tạo mới</h6>
                    <h6 class="mb-0" v-if="disabled">Sửa</h6>
                </template>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Name (*)">
                            <b-form-input
                                v-model="input.name"
                                placeholder="Name"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Code (*)">
                            <b-form-input
                                v-model="input.code"
                                placeholder="Code"
                                :disabled="this.id != '' && this.id != null"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Callback Url (*)">
                            <b-form-input
                                v-model="input.callback_url"
                                placeholder="Callback url"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="PublicKey (*)">
                            <b-form-textarea
                                v-model="input.public_key"
                                rows="6"
                                placeholder="Public key"
                            ></b-form-textarea>
                        </b-form-group>
                        <b-form-group label="Trạng thái">
                            <b-form-select
                                v-model="input.status"
                                :options="this.options.status">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <b-button class="mr-2" v-if="this.id == '' || this.id == null" variant="outline-primary" @click="store()"
                        >Thêm mới
                        </b-button>
                        <b-button class="mr-2" v-if="this.id != '' && this.id != null" variant="outline-primary" @click="update()"
                        >Lưu
                        </b-button>
                        <b-button class="mr-2" variant="outline-danger" @click="cancel()">Hủy</b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import "vue2-datepicker/index.css";
import CmsRepository from "@/core/repositories/cmsRepository";

Vue.use(Notifications);
export default {
    mixins: [Common],
    data() {
        return {
            id:null,
            disabled: false,
            input: {
                name: null,
                code: null,
                public_key: null,
                callback_url: null,
                status: 2,
            },
            options: {
                status: [
                    {value: null, text: "Tất cả"},
                    {value: 2, text: "Kích hoạt"},
                    {value: 1, text: "Chưa kích hoạt"},
                    // {value: -1, text: "Đã xóa"},
                ],
            },
        };
    },
    async created() {

    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config mail merchant" , route: '/tools/mail-merchant'},
        ]);
        if (this.$route.query.id != '' && this.$route.query.id != null) {
            this.getDetail(this.$route.query.id)
            this.id = this.$route.query.id;
        }
    },
    methods: {
        cancel() {
            return this.$router.push({name: "mailMerchant"});
        },
        validateStoreForm() {
            if (this.input.name === "" || this.input.name === null) {
                this.notifyAlert("warn", "name không được để trống");
                return false;
            }
            if (this.input.code === "" || this.input.code === null) {
                this.notifyAlert("warn", "code không được để trống");
                return false;
            }
            if (this.input.public_key === "" || this.input.public_key === null) {
                this.notifyAlert("warn", "public_key không được để trống");
                return false;
            }
            if (this.input.callback_url === "" || this.input.callback_url === null) {
                this.notifyAlert("warn", "callback_url không được để trống");
                return false;
            }
            return true;
        },
        store() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = {...this.input};
            this.$bus.$emit("show-loading", true);
            CmsRepository.storeMailMerchant(inputParam)
                .then((response) => {
                    if (response) {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "mailMerchant"});
                    } else {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        async getDetail(id) {
            this.$bus.$emit("show-loading", true);
            await CmsRepository.detailMailMerchant(id)
                .then((response) => {
                    if (response.data.error_code !== 0) {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }

                    let result = response.data.data;
                    this.input.name = result.name;
                    this.input.code = result.code;
                    this.input.public_key = result.public_key;
                    this.input.callback_url = result.callback_url;
                    // this.input.status = {
                    //     'text': result.status ==2 ? "Kích hoạt" : "Chưa kích hoạt",
                    //     'value':result.status
                    // };
                    this.$bus.$emit("show-loading", false);
                })
                .catch((error) => {
                    alert(error);
                    this.$bus.$emit("show-loading", false);
                });
        },
        update() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = this.input;
            this.$bus.$emit("show-loading", true);
            inputParam.id = this.id
            CmsRepository.updateMailMerchant(this.id, inputParam)
                .then((response) => {
                    if (response.data.error_code == 1) {
                        // this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({ name: "mailMerchant" });
                    } else {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({ name: "mailMerchant" });
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        validateEmail (email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    },
};
</script>


